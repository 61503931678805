import PotreeViewer from '../../components/PotreeViewer/PotreeViewer';

type Props = {
  options: object;
  doLogout: () => void;
  doLogin: () => void;
  userOption: string;
  demoChoice: number | null;
};
const Viewer = ({
  options,
  doLogout,
  doLogin,
  userOption,
  demoChoice,
}: Props) => {
  return (
    <>
      <PotreeViewer
        options={options}
        doLogout={doLogout}
        doLogin={doLogin}
        userOption={userOption}
        demoChoice={demoChoice}
      />
    </>
  );
};

export default Viewer;
