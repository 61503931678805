import { useState, useRef } from 'react';
import styled from 'styled-components';
import Reaptcha from 'reaptcha'; // the keys will need to be added in .env!
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '../../components/Buttons/Buttons';
import ScreenSizes from '../../screensizes'


type Props = {
  message: string;
};

export default function Contact(props: Props) {
  const captchaRef = useRef(null);
  const [captchaState, setCaptchaState] = useState(false);
  const navigate = useNavigate();
  const verify = () => {
    // @ts-ignore: Object is possibly 'null'.
    captchaRef.current.getResponse().then((res) => {
      setCaptchaState(true);
    });
  };

  const location = useLocation();
  const origin = location.pathname.substring(1,).replace('contact-','');
  const phoneRegExp =
    /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g;
  const contactForm = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      body: props.message,
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().label('Name').required('Full Name Required'),
      email: Yup.string()
        .trim()
        .email()
        .label('Email')
        .required('Email Required'),
      phone: Yup.string()
        .trim()
        .matches(phoneRegExp, 'Phone number is not valid'),
      body: Yup.string().trim().label('Message').required('Message Required'),
    }),
    onSubmit: function (values) {
      // @ts-ignore: Object is possibly 'null'.
      const token = captchaRef.current.getResponse();
      token.then((value: any) => {
        if (value === '') {
          alert('Please fill out the ReCAPTCHA');
        } else {
          sendMessage(values);
          alert(
            `Thanks for contacting us ${values.name.trim()}! A representative will be in touch.`
          );
        }
      });
      // @ts-ignore: Object is possibly 'null'.
      captchaRef.current.reset();
    },
  });

  async function sendMessage(values: any) {
    let bodyMsg = `Contact Name: ${values.name.trim()} \n`
    bodyMsg += `${values.phone.trim() === '' ? '' : 'Phone number: ' + values.phone.trim()} \n`
    bodyMsg += values.body.trim()
    const message = {
      subject: `New Propervis contact: ${origin}`,
      fromAddress: values.email.trim(),
      body: bodyMsg,
    };
    await axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: 'https://www.propervis.com/messenger/new',
      data: message,
    })
      .then((response) => navigate('/landing'))
      .catch((error) => {
        // console.log(error);
      });
  }

  return (
    <ContactForm>
      <Form onSubmit={contactForm.handleSubmit}>
        <a href="/">
          <BigLogoImg
            src="https://cdn.propervis.com/assets/logo-icon-text-vertical.svg"
            alt="ProperVisLogo"
          />
          <SmallLogoImg
            src="https://cdn.propervis.com/assets/logo-icon-text-horizontal.svg"
            alt="ProperVisLogo"
          />
        </a>

        <h1>Send A Message</h1>
        <p>We can Help! Just fill out the form below.</p>
        <br />

        <InputGroup>
          <input
            type="text"
            id="name"
            className={`input ${
              contactForm.touched.name &&
              contactForm.errors.name &&
              'border-red'
            }`}
            onChange={contactForm.handleChange}
            onBlur={contactForm.handleBlur}
            value={contactForm.values.name}
            placeholder={
              contactForm.touched.name && contactForm.errors.name
                ? contactForm.errors.name
                : 'Full Name *'
            }
          />
        </InputGroup>

        <InputGroup>
          <input
            type="text"
            id="email"
            className={`input ${
              contactForm.touched.email &&
              contactForm.errors.email &&
              'border-red'
            }`}
            onChange={contactForm.handleChange}
            onBlur={contactForm.handleBlur}
            value={contactForm.values.email}
            placeholder={
              contactForm.touched.email && contactForm.errors.email
                ? contactForm.errors.email
                : 'Email Address *'
            }
          />
        </InputGroup>

        <InputGroup>
          <input
            type="text"
            id="phone"
            className={`input ${
              contactForm.touched.phone &&
              contactForm.errors.phone &&
              'border-red'
            }`}
            onChange={contactForm.handleChange}
            onBlur={contactForm.handleBlur}
            value={contactForm.values.phone}
            placeholder={
              contactForm.touched.phone && contactForm.errors.phone
                ? contactForm.errors.phone
                : 'Phone Number'
            }
          />
        </InputGroup>

        <InputGroup>
          <span
            className={`txtAreaMsg ${
              contactForm.touched.body && contactForm.errors.body && 'error-red'
            }`}
          >
            {contactForm.touched.body && contactForm.errors.body
              ? contactForm.errors.body
              : 'Message *'}
          </span>
          <textarea
            id="body"
            rows={8}
            cols={40}
            className={`input ${
              contactForm.touched.body &&
              contactForm.errors.body &&
              'border-red'
            }`}
            onChange={contactForm.handleChange}
            onBlur={contactForm.handleBlur}
            value={contactForm.values.body}
          ></textarea>
        </InputGroup>

        <Reaptcha
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          ref={captchaRef}
          onVerify={verify}
        />

        <Button
          buttonStyle="blueSquare"
          type="submit"
          disabled={!(contactForm.isValid && contactForm.dirty && captchaState)}
        >
          Submit
        </Button>
        <Button buttonStyle="whiteSquare" onClick={() => navigate('/')}>
          Cancel
        </Button>
      </Form>
    </ContactForm>
  );
}

const ContactForm = styled.div`
  width: -webkit-fill-available;
  padding-top: 30px;
  display: block;
  text-align: center;
`;

const Form = styled.form`
  margin: 0 0 15px;
  text-align: center;
  display: inline-block;
  input,
  textarea {
    max-width: 350px;
    width: 100%;
    padding: 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border: 1px solid #c7c6c4;
    border-radius: 4px;
    ::placeholder {
      font-style: italic;
      font-family: var(--interface-font);
    }
  }

  textarea {
    padding: 35px 15px 15px;
    margin-bottom: 10px;
    min-height: 50px;
    height: 100%;
    font-weight: bold;
    font-family: var(--interface-font);
  }

  .txtAreaMsg {
    margin: 15px;
    position: absolute;
    font-style: italic;
    font-family: var(--interface-font);
    font-size: smaller;
    color: #777777;
  }

  .txtAreaMsg.error-red {
    color: red;
  }

  h1 {
    font: normal normal bold 24px/29px Montserrat;
    margin: 5px;
  }

  p {
    font: normal normal normal 16px/20px Open Sans;
  }

  .g-recaptcha {
    text-align: -webkit-center;
    margin: 10px 0;
    transform: scale(1.155);
  }

  label {
    display: inline-block;
    margin-bottom: 3px;
  }

  .border-red {
    border-color: red;
    ::placeholder {
      font-style: italic;
      color: red;
      font-family: var(--interface-font);
    }
  }

  button {
    max-width: 350px;
    width: 100%;
    margin: 5px 0;
  }
`;
const InputGroup = styled.div`
  margin: 10px 0;
`;

const BigLogoImg = styled.img`
  display: inline-block;
  max-width: 272px;
  margin: 100px 0 50px;
  @media screen and (max-width: ${ScreenSizes.mobileL}){
    display: none;
  }

`;
const SmallLogoImg = styled.img`
  display: none;
  max-width: 272px;
  margin: 36px 0 24px;
  @media screen and (max-width: ${ScreenSizes.mobileL}){
    display: inline-block;
  }
`;
