import { Container } from './styles';
import { ReactSVG } from 'react-svg';


export interface PropsInterface extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  buttonStyle?:
  | 'signin'
  | 'signup'
  | 'blueRound'
  | 'whiteSquare'
  | 'blueSquare'; // styling options
  disabled?: boolean; // make the button disabled or not
  iconName?: string;
}

export const Button = ({ onClick, children, buttonStyle, disabled }: PropsInterface) => {
  return (
    <Container onClick={onClick} buttonStyle={buttonStyle} disabled={disabled}>
      {children}
    </Container>
  );
};

export const IconButton = ({
  onClick,
  children,
  buttonStyle,
  disabled,
  iconName,
}: PropsInterface) => {
  const iconBasePath = 'assets/icons/';
  const iconPath = `${iconBasePath}${iconName}.svg`;
  return (
    <Button onClick={onClick} buttonStyle={buttonStyle} disabled={disabled}>
      <ReactSVG src={iconPath} />
      {children}
    </Button>
  );
};
