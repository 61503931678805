import { demoItems } from './demoItems';
import DemoCard from '../DemoCard/DemoCard';
import styled from 'styled-components';

type Props = {
  selectDemoCard: (choice: string) => void;
};
const DemoList = ({ selectDemoCard }: Props) => {
  return (
    <DemoListWrapper>
      {demoItems.map((demoItem) => (
        <DemoCard demoItem={demoItem} selectDemoCard={selectDemoCard} key={demoItem.label}/>
      ))}
    </DemoListWrapper>
  );
};

export default DemoList;

const DemoListWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
`;
