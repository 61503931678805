import styled from 'styled-components';
import ScreenSizes from '../../screensizes'
import { useNavigate } from 'react-router-dom';

type Props = {};

const Footer = (props: Props) => {
  let navigate = useNavigate();
  const routeChange = (path: string) => {
    navigate(path);
  };

  return (
    <FooterWrapper>
      <FooterTop>
        <FooterTopContainer>
        <LogoBox>
          <img
            src="https://cdn.propervis.com/assets/logo-icon-text-vertical-alt.svg"
            alt="logo"
          />
        </LogoBox>
        <LinksBox>
          <Quote>If you can walk around it, we can capture it.</Quote>
          <LinkColumns>
            <LinkColumn>
              <TopicLink>PROPERTY</TopicLink>
              <FooterTopLink onClick={() => routeChange('/contact-landscapes')}>
                Landscapes
              </FooterTopLink>
              <FooterTopLink onClick={() => routeChange('/contact-buildings')}>
                Buildings
              </FooterTopLink>
              <FooterTopLink onClick={() => routeChange('/contact-property')}>
                Other
              </FooterTopLink>
            </LinkColumn>
            <LinkColumn>
              <TopicLink>VISUALS</TopicLink>
              <FooterTopLink
                onClick={() => routeChange('/contact-visual-tools')}
              >
                Scan & View
              </FooterTopLink>
              <FooterTopLink
                onClick={() => routeChange('/contact-visual-tools')}
              >
                Measure
              </FooterTopLink>
              <FooterTopLink
                onClick={() => routeChange('/contact-visual-tools')}
              >
                Export
              </FooterTopLink>
            </LinkColumn>
            <LinkColumn>
              <TopicLink>PRICING</TopicLink>
              <FooterTopLink
                onClick={() => routeChange('/contact-project-sizes')}
              >
                Property Sizes
              </FooterTopLink>
              <FooterTopLink
                onClick={() => routeChange('/contact-recurring-scans')}
              >
                Recurring Scans
              </FooterTopLink>
              <FooterTopLink
                onClick={() => routeChange('/contact-get-a-quote')}
              >
                Get a Quote
              </FooterTopLink>
            </LinkColumn>
            <LinkColumn>
              <TopicLink>SUPPORT</TopicLink>
              <FooterTopLink
                onClick={() => routeChange('/contact-why-propervis')}
              >
                Why ProperVis?
              </FooterTopLink>
              <FooterTopLink onClick={() => routeChange('/contact-support')}>
                FAQ's
              </FooterTopLink>
              <FooterTopLink onClick={() => routeChange('/contact-support')}>
                Contact
              </FooterTopLink>
            </LinkColumn>
          </LinkColumns>
        </LinksBox></FooterTopContainer>
      </FooterTop>
      <FooterBottom>
        <FooterBottomLeft>
          <CopyRight>&copy;2022 ProperVis. All Rights Reserved.</CopyRight>
          {/* <FooterBottomTextLink>Privacy</FooterBottomTextLink>
          <FooterBottomTextLink>Terms</FooterBottomTextLink>
          <FooterBottomTextLink>Legal</FooterBottomTextLink> */}
        </FooterBottomLeft>
        {/* <FooterBottomRight>
          <FooterSocialLinks>
            <img src='assets/images/socials.png' alt='logo'/>
          </FooterSocialLinks>
        </FooterBottomRight> */}
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.div`
  width: 100%;
  color: var(--theme-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 340px;
  width: 100%;
  background-color: var(--theme-blue-001);
  `;

const FooterTopContainer=styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
align-items: center;
padding: 24px;
width: 100%;
max-width: 1200px;
@media screen and (max-width: ${ScreenSizes.laptop}){
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-between;
}
@media screen and (max-width: ${ScreenSizes.mobileL}){
}

`

const FooterBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100px;
  width: 100%;
  background-color: var(--theme-blue-002);
  font-family: var(--button-font);
  @media screen and (max-width: ${ScreenSizes.laptop}){

  }
`;
const LogoBox = styled.div`
  margin: 0 24px 0 0;
  img {
    height: 160px;
  }
  @media screen and (max-width: ${ScreenSizes.laptop}){
    margin: 0 auto;
    img{
      margin-top: 24px;
    }
  }
`;
const LinksBox = styled.div`
max-width: 800px;
width: 100%;
`;

const Quote = styled.div`
  font-size: 2.4rem;
  margin: 24px auto;
  font-weight: 300;
  font-style: italic;
  opacity: 66%;
`;

const LinkColumns = styled.div`
  font-family: var(--button-font);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${ScreenSizes.laptop}){
    justify-content: space-between;
    align-items: center;
    margin: 1.6rem;
  }
@media screen and (max-width: ${ScreenSizes.mobileL}){
flex-direction: column;
}
`;

const LinkColumn = styled.div`
    @media screen and (max-width: ${ScreenSizes.laptop}){

    margin: 1.6rem;
    margin-top: 2rem;
  }
    @media screen and (max-width: ${ScreenSizes.mobileL}){
    margin: 1.6rem;
    margin-top: 3.6rem;
    text-align: center;
  }

  text-align: left;
`;

const TopicLink = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
`;

const FooterTopLink = styled.div`
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 300;
  margin-top: 15px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

const FooterBottomLeft = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 40%;
`;

const CopyRight = styled.div`
  opacity: 66%;
`;

// const FooterBottomTextLink = styled.div`
//   margin-left: 16px;
// `;

// const FooterBottomRight = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

// const FooterSocialLinks = styled.div`
//   img {
//     height: 48px;
//   }
// `;
