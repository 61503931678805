import styled from 'styled-components';

type DemoItem = {
  demoItem: { label: string; thumbnail: string };
  selectDemoCard: any;
};

const DemoCard = ({ demoItem, selectDemoCard }: DemoItem) => {
  return (
    <StyledDemoCard onClick={() => selectDemoCard(demoItem.label)}>
      <img src={demoItem.thumbnail} alt={demoItem.label} />
      <span>{demoItem.label}</span>
    </StyledDemoCard>
  );
};

export default DemoCard;

const StyledDemoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  height: 415px;
  width: 375px;
  font-family: var(--interface-font);
  font-size: 2rem;
  background: radial-gradient(var(--theme-grey-004), var(--theme-grey-003));
  margin: 24px;
  &:hover {
    cursor: pointer;
    background: var(--theme-blue-006);
    color: var(--theme-secondary);
  }
  img {
    max-width: 300px;
  }
`;
