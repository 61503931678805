import styled, { css } from 'styled-components';
import { PropsInterface } from './Buttons';

const BUTTONSTYLES = {

  signin: css`
    background-color: var(--theme-transparent);
    color: var(--theme-secondary);
    border: 2px var(--theme-grey-001) solid;
    padding: 12px 20px 18px 20px;
    &:hover {
      border: 2px var(--theme-blue-006) solid;
      background-color: var(--theme-blue-006);
    }
    margin-right: 10px;
  `,

  signup: css`
    background-color: var(--theme-secondary);
    color: var(--theme-blue-001);
    border: 2px;
    padding: 15px 20px;
    &:hover {
      color: var(--theme-secondary);
      background-color: var(--theme-blue-006);    }
  `,

  blueRound: css`
    background-color: var(--theme-blue-001);
    color: var(--theme-secondary);
    padding: 20px 68px;
    &:hover {
      background-color: var(--theme-blue-006);
    }
    font-weight: 500;
    border-radius: 29px;
  `,
  whiteSquare: css`
    background-color: var(--theme-background-primary);
    color: var(--theme-blue-001);
    padding: 18px;
    &:hover {
      background-color: var(--theme-blue-006);
      color: var(--theme-secondary);
    }
    font-weight: 500;
    border: 2px solid var(--theme-grey-002);

  `,

  blueSquare: css`
    background-color: var(--theme-blue-001);
    color: var(--theme-secondary);
    padding: 18px;
    border: 2px solid var(--theme-grey-002);
    border-radius: 4px;
    &:hover {
      background-color: var(--theme-blue-006);
    }
  `
};

const DISABLED = css`
  cursor: not-allowed;
  background: #d4d4d4;
  color: #f5f5f5;
  &:hover {
    background: #d4d4d4;
    color: #f5f5f5;
    box-shadow: none;
  }
`;

export const Container = styled.button<PropsInterface>`
  padding: 10px 15px;
  cursor: pointer;
  border: 2px;
  border-radius: 4px;
  font-family: var(--button-font);
  font-size: 1.6rem;
  font-weight: 300;
  outline: none;
  white-space: nowrap;
  transition: all 0.2s;
  ${(props) => props.buttonStyle && BUTTONSTYLES[props.buttonStyle]}
  ${(props) => props.disabled && DISABLED}
`;
