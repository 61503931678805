import { Navigate, Outlet } from 'react-router-dom';
import auth from '../../utilities/Authenticator';

type ProtectedProps = {
  redirectPath?: string;
  children?: JSX.Element;
};

const ProtectedRoute = ({
  redirectPath = 'landing',
  children,
}: ProtectedProps) => {
  if (!auth.checkIfCookieIsValidForRoute()) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
