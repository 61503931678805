type Props = {
  handleUserToken: (token: string) => void;
};

const Login = ({ handleUserToken }: Props) => {
  const token = window.location.hash.split('&')[0].split('=')[1];
  if (token) {
    handleUserToken(token);
  }

  return <div>Validating Token...</div>;
};

export default Login;
