import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { Button } from '../../components/Buttons/Buttons';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import DemoList from '../../components/DemoList/DemoList';
import ScreenSizes from '../../screensizes'


type HeaderProps = {
  authClickHandler: () => void;
  signupClickHandler: () => void;
  isAuthenticated: boolean;
  selectDemoCard: (choice: string) => void;
};

type ImgLinkProps = {
  src: string;
  link: string;
};

function Landing({
  isAuthenticated,
  authClickHandler,
  signupClickHandler,
  selectDemoCard,
}: HeaderProps) {
  const ImgLink = ({ src, link }: ImgLinkProps) => {
    const img = (
      <img src={`https://cdn.propervis.com/assets/${src}.png`} alt={`${src}`} />
    );
    return <Link to={`/${link}`}><ImageWrapper>{img}</ImageWrapper></Link>;
  };

  let navigate = useNavigate();
  const routeChange = (path: string) => {
    navigate(path);
  };

  return (
    <PageWrapper>
      <LandingHeader>
        <HeaderContentWrapper>
        <Header
          headerStyle="landing"
          authClickHandler={authClickHandler}
          signupClickHandler={signupClickHandler}
          isAuthenticated={isAuthenticated}
        />
        <HeaderCopy>
          <h1>Get precision 3D visuals of your property quickly.</h1>
          <h2>What's your interest?</h2>
        </HeaderCopy>

        <InterestBox>
          <ImgLink src="thumbnail-landscapes-hover" link="contact-landscapes" />
          <ImgLink src="thumbnail-buildings-hover" link="contact-buildings" />
          <ImgLink src="thumbnail-automotive-hover" link="contact-automotive" />
          <ImgLink src="thumbnail-products-hover" link="contact-products" />
        </InterestBox>
        <BottomCurve />
        </HeaderContentWrapper>
      </LandingHeader>
      <LandingContent>
        <DemoListHeader>A few of our proper visuals:</DemoListHeader>
        <DemoList selectDemoCard={selectDemoCard} />
        <InfographicHeader>
          Our mapping technology lets you...
        </InfographicHeader>
        <BigInfographic
          src="https://cdn.propervis.com/assets/infographic.png"
          alt="infographic"
        />
                <SmallInfographic
          src="https://cdn.propervis.com/assets/mobile-infographic.png"
          alt="infographic"
        />

        <Button
          buttonStyle="blueRound"
          onClick={() => routeChange('/contact-your-own-visual')}
        >
          GET YOUR OWN VISUAL
        </Button>
        <Greybox>
          <h1>Not sure where to start?</h1>
          <Button
            buttonStyle="whiteSquare"
            onClick={() => routeChange('/contact-sales')}
          >
            TALK TO A SALES AGENT
          </Button>
          <Button
            buttonStyle="whiteSquare"
            onClick={() => routeChange('/contact-support')}
          >
            CONTACT CUSTOMER SUPPORT
          </Button>
        </Greybox>
      </LandingContent>
      <Footer />
    </PageWrapper>
  );
}

export default Landing;

const LandingHeader = styled.div`
  width: 110vw;
  height: 100%;
  margin: -24px -5vw 0px -5vw;
  background: url("assets/images/header-bg.jpg") no-repeat;
  background-size: 110vw 100%;
`;

const HeaderContentWrapper = styled.div`
  margin: 24px 0 auto;
  margin-left: 5vw;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeaderCopy = styled.div`
  font-family: var(--content-font);
  text-shadow: 0px 2px 4px var(--theme-shadow);
  h1 {
    margin-top: 3.6rem;
    font-size: 3.2rem;
    font-weight: 500;
    padding: 0 24px;
  }
  h2 {
    margin-top: 10px;
    margin-bottom: 24px;
    padding: 0 24px;
    font-size: 2.6rem;
    font-weight: 400;
    opacity: 0.8;
  }
  `;

const LandingContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--theme-primary);
  background-color: var(--theme-secondary);
  h2.info {
    padding: 0 16px;
    font-family: var(--content-font);
    font-style: italic;
    color: #001632;
    opacity: 80%;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--theme-transparent);
  color: #fff;
  text-align: center;
`;

const BottomCurve = styled.div`
  background-color: white;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  height: 60px;
  width: 120%;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5) inset ;
`;

const InterestBox = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 8px 8px 60px 8px;
  img {
    transition: clip-path 0.1s ease;
    clip-path: circle(50%);
    &:hover {
      clip-path: circle(100%);
    }
  }
`;

const ImageWrapper = styled.div`
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.5))  ;
    margin: 8px;
`

const DemoListHeader = styled.p`
  font-family: var(--content-font);
  font-size: 3rem;
  font-weight: 300;
  line-height: 28px;
  opacity: 0.8;
`;
const InfographicHeader = styled(DemoListHeader)`
  margin-top: 48px;
  font-style: italic;
`;
const BigInfographic = styled.img`
  width: 90%;
  max-width: 950px;
  margin: 32px auto;
  @media screen and (max-width: ${ScreenSizes.tablet}){
display: none;  }
`;
const SmallInfographic = styled.img`
  width: 90%;
  max-width: 400px;
  margin: 32px auto;
  display: none;
  @media screen and (max-width: ${ScreenSizes.tablet}){
display: block;  }
`;

const Greybox = styled.div`
  margin: 100px 0 0;
  padding: 80px 0;
  width: 100%;
  background-color: var(--theme-grey-002);
  h1 {
    font-size: 3rem;
    font-weight: 300;
    font-family: var(--content-font);
    margin-bottom: 15px;
    opacity: 0.7;
  }
  button {
    margin: 10px;
    width: 20em;
  }
`;
