import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  :root {
    font-size: 62.5%;

    // Colors
    --theme-primary: #001632;
    --theme-secondary: #FFFFFF;
    --theme-background-primary: #FAFBFD;
    --theme-background-secondary: #61738B;
    --theme-shadow: #00000029;
    --theme-blue-001: #0563B0;
    --theme-blue-002: #00549A;
    --theme-blue-003: #0063B0;
    --theme-blue-004: #003B6F;
    --theme-blue-005: #1a62a2;
    --theme-blue-006: #023B6F;
    --theme-transparent: #00000000;
    --theme-grey-001: #D1D6DD;
    --theme-grey-002: #E0E1E3;
    --theme-grey-003: #D9DaDC;
    --theme-grey-004: #EAEAEC;

    // Font Families
    --button-font: 'Figtree', sans-serif;
    --interface-font: 'Open Sans', sans-serif;
    --content-font: 'Montserrat', sans-serif;

  }

  html {
    background-color: var(--theme-background-primary);
    color: var(--theme-primary);
    height: 100vh;

    overflow-x: hidden;
  }

  /* Put dark mode colors in here */
  html.dark {
    --theme-background-primary: #001632;
    --theme-primary: #FAFBFD;
  }

  /* This is for gradually switching from dark/light mode */
  html.theming,
  html.theming * {
    transition: all 0.2s linear;
  }

  body {
    background-color: var(--theme-background-primary);
    height: 100vh;
    width: 100%;
    font-size: 1.6rem;
    font-family: var(--content-font);
    overflow-y: auto;
  }

  a:hover, a:visited, a:link, a:active {
    color: inherit;
    text-decoration: none;
  }
`
