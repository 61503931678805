import jwt from 'jsonwebtoken';
import appConfig from '../config';
import jwkToPem from 'jwk-to-pem';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const cognitoClientId = appConfig.ClientId;
const cognitoPublicKey = appConfig.CognitoPublicKey;
const callBack =
  process.env.NODE_ENV === 'production'
    ? appConfig.prodUri
    : appConfig.localUri;

class Authenticator {
  cognitoPublicKey!: object;
  cognitoClientId!: string;
  callBack!: string;
  static cognitoClientId: string;
  static callBack: string;
  static cognitoPublicKey: object;
  static config: {
    region: string;
    IdentityPoolId: string;
    UserPoolId: string | undefined;
    ClientId: string | undefined;
    localUri: string;
    prodUri: string;
    CognitoPublicKey: object;
  };

  static login(): void {
    window.location.replace(
      `https://${
        process.env.NODE_ENV === 'production' ? 'auth' : 'dev-auth'
      }.propervis.com/login?response_type=token&client_id=${cognitoClientId}&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${callBack}/login`
    );
  }

  static logout(user: object, setUser: any): void {
    setUser({ ...user, authenticated: false, username: '' });
    cookies.remove('token', { path: '/' });
  }

  static verifyToken(token: string): any {
    const publicKey = JSON.parse(cognitoPublicKey);
    const pem = jwkToPem(publicKey);
    try {
      const verified = jwt.verify(token, pem);
      return verified;
    } catch (err) {
      return false;
    }
  }

  static cookieHasToken(): boolean {
    if (cookies.get('token')) {
      return true;
    } else {
      return false;
    }
  }
  static storeTokenInCookie(token: string): void {
    cookies.set('token', token, { path: '/' });
  }
  static processNewToken(token: string, user: object, setUser: any): boolean {
    const verified = this.verifyToken(token);
    if (verified) {
      this.storeTokenInCookie(token);
      setUser({
        ...user,
        authenticated: true,
        username: verified.name,
        userId: verified.sub,
      });
      return true;
    } else {
      return false;
    }
  }

  static checkForTokenOnRefresh(user: object, setUser: any): void {
    if (this.cookieHasToken()) {
      const verified = this.verifyToken(cookies.get('token'));
      if (verified) {
        setUser({
          ...user,
          authenticated: true,
          username: verified.name,
          userId: verified.sub,
        });
      }
    }
  }

  static checkIfCookieIsValidForRoute(): boolean {
    if (this.cookieHasToken()) {
      const verified = this.verifyToken(cookies.get('token'));
      if (verified) {
        return true;
      } else {return false};
    } else{ return false};
  }
}

export default Authenticator;
