type ConfigItem = {
  region: string;
  IdentityPoolId: string;
  UserPoolId: string | undefined;
  ClientId: string | undefined;
  localUri: string;
  prodUri: string;
  CognitoPublicKey: any;
};

const config: ConfigItem = {
  region: 'us-east-1',
  IdentityPoolId: '',
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  CognitoPublicKey: process.env.REACT_APP_COGNITO_PUB_KEY,
  localUri: process.env.REACT_APP_DEV_URI ?? 'http://localhost:3001',
  prodUri: 'https://www.propervis.com',
};

export default config;
