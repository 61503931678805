import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './components/Login/Login';
import NotFound from './views/NotFound/NotFound';
import Landing from './views/Landing/Landing';
import Contact from './views/Contact/Contact';
import Viewer from './views/Viewer/Viewer';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import auth from './utilities/Authenticator';

export default function App() {
  const options = [
    {
      value:
        'https://cdn.propervis.com/users/authenticated/FirstFloor2/metadata.json',
      label: (
        <div className="label">
          <span className="label-span">First Floor</span>
          <span className="date-span">Nov 16, 2022</span>
        </div>
      ),
    },
    {
      value:
        'https://cdn.propervis.com/users/authenticated/CraneScan2/metadata.json',
      label: (
        <div className="label">
          <span className="label-span">Crane</span>
          <span className="date-span">Nov 16, 2022</span>
        </div>
      ),
    }
  ];
  const demoOptions = [
    {
      value:
        'https://cdn.propervis.com/users/5fc9feaa-01e6-463a-93f2-2915db498e57/lion/lion_takanawa/cloud.js',
      label: (
        <div className="label">
          <span className="label-span">Takanawa Lion</span>
          <span className="date-span">Oct 24, 2022</span>
        </div>
      ),
      simpleLabel: 'Takanawa Lion',
    },
    {
      value:
        'https://cdn.propervis.com/users/5fc9feaa-01e6-463a-93f2-2915db498e57/44Station/metadata.json',
      label: (
        <div className="label">
          <span className="label-span">44 Station</span>
          <span className="date-span">Nov 1, 2022</span>
        </div>
      ),
      simpleLabel: '44 Station',
    },
  ];

  const navigate = useNavigate();

  const [user, setUser] = useState({
    authenticated: false,
    username: '',
    userId: '',
  });

  const [demoChoice, setDemoChoice] = useState(0);

  useEffect(() => {
    auth.checkForTokenOnRefresh(user, setUser);
  }, []);

  function doLogout(): void {
    auth.logout(user, setUser);
    navigate('/landing', { replace: true });
  }

  function doLogin(): void {
    auth.login();
  }

  function doSignup(): void {
    navigate('/contact-membership', { replace: true });
  }

  function selectDemoCard(choice: string): void {
    console.log(choice);
    const choiceIndex = demoOptions
      .map((object) => object.simpleLabel)
      .indexOf(choice);
    setDemoChoice(choiceIndex);
    console.log(choiceIndex);
    navigate('/demo', { replace: true });
  }

  function handleNewCognitoToken(token: string): void {
    const isGoodToken = auth.processNewToken(token, user, setUser);
    if (isGoodToken) {
      navigate('/viewer', { replace: true });
    } else {
      navigate('/landing', { replace: true });
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="login"
          element={<Login handleUserToken={handleNewCognitoToken} />}
        />
        <Route path={'/'} element={<Navigate to="/landing" replace />} />
        <Route
          path={'landing'}
          element={
            <Landing
              authClickHandler={user.authenticated ? doLogout : doLogin}
              signupClickHandler={doSignup}
              isAuthenticated={user.authenticated}
              selectDemoCard={selectDemoCard}
            />
          }
        />
        <Route
          path="property"
          element={
            <Contact message="I'd like to learn more about getting a visual of my property." />
          }
        />
        <Route
          path="visuals"
          element={
            <Contact message="I'd like to learn more about your visuals technology." />
          }
        />
        <Route
          path="pricing"
          element={
            <Contact message="I'd like to learn more about your pricing." />
          }
        />
        <Route
          path="support"
          element={
            <Contact message="I'd like to speak with a customer support representative." />
          }
        />
        <Route path={'*'} element={<NotFound />} />
        <Route
          path="contact-property"
          element={
            <Contact message="I'd like to learn more about getting a visual of my property." />
          }
        />
        <Route
          path="contact-visuals"
          element={
            <Contact message="I'd like to learn more about your visuals technology." />
          }
        />
        <Route
          path="contact-pricing"
          element={
            <Contact message="I'd like to learn more about your pricing." />
          }
        />
        <Route
          path="contact-support"
          element={
            <Contact message="I'd like to speak with a customer support representative." />
          }
        />
        <Route
          path="contact-membership"
          element={<Contact message="I'd like to become a member." />}
        />
        <Route
          path="contact-buildings"
          element={
            <Contact message="I'd like to get a proper building visual." />
          }
        />
        <Route
          path="contact-automotive"
          element={
            <Contact message="I'd like to get a proper automotive visual." />
          }
        />
        <Route
          path="contact-products"
          element={
            <Contact message="I'd like to get a proper product visual." />
          }
        />
        <Route
          path="contact-landscapes"
          element={
            <Contact message="I'd like to get a proper landscape visual." />
          }
        />
        <Route
          path="contact-your-own-visual"
          element={<Contact message="I'd like to get a proper visual." />}
        />
        <Route
          path="contact-sales"
          element={
            <Contact message="I'd like to speak with a sales representative." />
          }
        />
        <Route
          path="contact-visual-tools"
          element={
            <Contact message="I'd like to learn more about your visual tools and viewer capabilities." />
          }
        />
        <Route
          path="contact-project-sizes"
          element={
            <Contact message="I'd like to learn more about your pricing for different project sizes." />
          }
        />
        <Route
          path="contact-recurring-scans"
          element={
            <Contact message="I'd like to learn more about your pricing for recurring scans." />
          }
        />
        <Route
          path="contact-get-a-quote"
          element={<Contact message="I'd like to get a quote." />}
        />
        <Route
          path="contact-why-propervis"
          element={<Contact message="I'd like to learn more about ProperVis" />}
        />
        <Route path="contact" element={<Contact message="" />} />
        <Route
          path="demo"
          element={
            <Viewer
              options={demoOptions}
              userOption={user.authenticated ? 'Sign Out' : 'Sign In'}
              doLogin={doLogin}
              doLogout={doLogout}
              demoChoice={demoChoice}
            />
          }
        />
        <Route element={<ProtectedRoute />}>
          <Route
            path="viewer"
            element={
              <Viewer
                options={options}
                userOption={user.authenticated ? 'Sign Out' : 'Sign In'}
                doLogin={doLogin}
                doLogout={doLogout}
                demoChoice={null}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
}
