import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ScreenSizes from '../../screensizes'
import HeaderNav from '../HeaderNav/HeaderNav';

type HeaderNavProps = {
  authClickHandler: () => void;
  signupClickHandler: () => void;
  isAuthenticated: boolean;
  headerStyle: any;
};

const Header = ({
  headerStyle,
  isAuthenticated,
  authClickHandler,
  signupClickHandler,
}: HeaderNavProps) => {
  return (
    <StyledHeader headerStyle={headerStyle}>
      <Link to="/landing">
        <Logo
          src={
            headerStyle === 'landing'
              ? 'https://cdn.propervis.com/assets/logo-icon-text-horizontal-alt.svg'
              : 'https://cdn.propervis.com/assets/logo-icon-text-horizontal.svg'
          }
          className="logo"
          alt="propervis logo"
        />
      </Link>
      <HeaderNav
        headerStyle={headerStyle}
        isAuthenticated={isAuthenticated}
        authClickHandler={authClickHandler}
        signupClickHandler={signupClickHandler}
      />
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.div<any>`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) =>
    props.headerStyle === 'landing'
      ? 'var(--theme-transparent)'
      : 'var(--theme-secondary)'};
  align-items: center;
  margin 24px auto;
  max-width: 1200px;
  @media screen and (max-width: ${ScreenSizes.laptop}){
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
`;

const Logo = styled.img`
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  margin: 10px 24px;
  @media screen and (max-width: ${ScreenSizes.mobileL}){
    width: 100%;
    margin: 0.6rem auto;
  }

`;

// const ThemeButton = styled.button`
//   padding: 8px 16px;
//   color: var(--theme-primary);
//   background: var(--theme-background-primary);
//   outline: none;
//   border-radius: 4px;
//   border: 1px solid var(--theme-primary);
//   cursor: pointer;
// `;

// const toggleTheme = () => {
//   document.documentElement.classList.add('theming');
//   document.documentElement.addEventListener(
//     'transitionend',
//     () => {
//       if (document.documentElement) {
//         document.documentElement.classList.remove('theming');
//       }
//     },
//     { once: true }
//   );
//   document.documentElement.classList.toggle('dark');
// };
