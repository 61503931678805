import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { IconButton } from '../Buttons/Buttons';
import ScreenSizes from '../../screensizes'
export default class PotreeViewer extends React.Component<any> {
  potreeContainerDiv: any;
  userButton: any;
  backgroundButton: any;
  userMenu: any;
  backgroundMenu: any;
  e: any;
  Potree: any;
  options: any;
  state: any;

  constructor(props: any) {
    super(props);
    this.Potree = window.Potree;
    this.potreeContainerDiv = React.createRef();
    this.userButton = React.createRef();
    this.backgroundButton = React.createRef();
    this.userMenu = React.createRef();
    this.backgroundMenu = React.createRef();
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.toggleBackgroundMenu = this.toggleBackgroundMenu.bind(this);
    this.closeUserMenu = this.closeUserMenu.bind(this);
    this.closeBackgroundMenu = this.closeBackgroundMenu.bind(this);
    this.handleUserMenuChange = this.handleUserMenuChange.bind(this);
    this.handleBackgroundMenuChange =
      this.handleBackgroundMenuChange.bind(this);
    this.closeOpenMenus = this.closeOpenMenus.bind(this);
    this.state = {
      isSmallScreen: false,
      isSideBarOpen: true,
      options: props.options,
      userMenuOptions: [{ value: props.userOption, label: props.userOption }],
      initialProjectChoice: props.demoChoice,
      backgroundMenuOptions: [
        { value: 'skybox', label: 'Skybox' },
        { value: 'black', label: 'Black' },
        { value: 'white', label: 'White' },
        { value: 'none', label: 'Gradient' },
      ],
      value: '',
      label: '',
      viewer: '',
      userMenuVisible: false,
      backgroundMenuVisisble: false,
    };
  }
  closeOpenMenus = (e: any) => {
    if (
      this.userMenu.current &&
      this.state.userMenuVisible &&
      !this.userMenu.current.contains(e.target) &&
      !this.userButton.current.contains(e.target)
    ) {
      this.closeUserMenu();
    }

    if (
      this.backgroundMenu.current &&
      this.state.backgroundMenuVisible &&
      !this.backgroundMenu.current.contains(e.target) &&
      !this.backgroundButton.current.contains(e.target)
    ) {
      this.closeBackgroundMenu();
    }
  };

  handleProjectChange(e: any) {
    this.setState({ value: e.value, label: e.label });
    this.loadNewPointCloud(e.value, this.state.viewer);
  }

  toggleSidebar() {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
  }

  toggleUserMenu() {
    this.setState({ userMenuVisible: !this.state.userMenuVisible });
  }

  closeUserMenu() {
    this.setState({ userMenuVisible: false });
  }

  closeBackgroundMenu() {
    this.setState({ backgroundMenuVisible: false });
  }

  handleUserMenuChange(e: any) {
    if (e.value === 'Sign Out') {
      this.props.doLogout();
    }
    if (e.value === 'Sign In') {
      this.props.doLogin();
    }
    this.setState({ userMenuVisible: false });
  }

  toggleBackgroundMenu() {
    this.setState({ backgroundMenuVisible: !this.state.backgroundMenuVisible });
  }

  handleBackgroundMenuChange(e: any) {
    this.state.viewer.setBackground(e.value);
    this.setState({ backgroundMenuVisible: false });
  }

  loadNewPointCloud(url: string, viewer: any): void {
    viewer.scene.pointclouds.forEach(function (layer: any) {
      viewer.scene.scenePointCloud.remove(layer);
    });
    viewer.scene.pointclouds.pop();
    this.Potree.loadPointCloud(url).then(
      (e: { pointcloud: any }) => {
        let pointcloud = e.pointcloud;
        let material = pointcloud.material;
        material.minSize = 2;
        material.pointSizeType = this.Potree.PointSizeType.ADAPTIVE;
        // material.activeAttributeName = "classification"
        viewer.scene.addPointCloud(pointcloud);

        viewer.fitToScreen();
      },
      (e: any) => console.error('ERROR: ', e)
    );
  }

  render() {
    return (
      <ViewerWrapper isSideBarOpen={this.state.isSideBarOpen}>
        <SidebarBurgerIconWrapper isSideBarOpen={this.state.isSideBarOpen}>
              <IconButton
                iconName='circle-xmark-solid'
                onClick={this.toggleSidebar}
              />
        </SidebarBurgerIconWrapper>
        <SidebarWrapper id="potree_sidebar_container" />
        <PotreeWrapper
          id="potree_render_area"
          ref={this.potreeContainerDiv}
          // style={{ left: this.state.isSideBarOpen ? '300px': '0px' }}
        >
          <UserMenuWrapper ref={this.userMenu} >
            {this.state.userMenuVisible ? (
              <Select
                options={this.state.userMenuOptions}
                onChange={this.handleUserMenuChange}
                onBlur={this.handleUserMenuChange}
                styles={propSelectStyles}
                className="userMenuContainer"
                classNamePrefix="userMenu"
                components={{
                  IndicatorSeparator: () => null,
                  Control: () => null,
                }}
                menuIsOpen={true}
              />
            ) : null}
          </UserMenuWrapper>
          <BackgroundMenuWrapper ref={this.backgroundMenu}>
            {this.state.backgroundMenuVisible ? (
              <Select
                menuPlacement="top"
                options={this.state.backgroundMenuOptions}
                onChange={this.handleBackgroundMenuChange}
                onBlur={this.handleBackgroundMenuChange}
                styles={propSelectStyles}
                className="backgroundMenuContainer"
                classNamePrefix="backgroundMenu"
                components={{
                  IndicatorSeparator: () => null,
                  Control: () => null,
                }}
                menuIsOpen={true}
              />
            ) : null}
          </BackgroundMenuWrapper>
          <SelectWrapper isSideBarOpen={this.state.isSideBarOpen}>
            <SelectContainer>
              <Select
                defaultValue={
                  this.state.initialProjectChoice
                    ? this.state.options[this.state.initialProjectChoice]
                    : this.state.options[0]
                }
                options={this.state.options}
                onChange={this.handleProjectChange}
                styles={propSelectStyles}
                placeholder="Project Name"
                isSearchable={false}
                className="projectSelectContainer"
                classNamePrefix="projectSelect"
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </SelectContainer>
          </SelectWrapper>
          <BurgerIconWrapper isSideBarOpen={this.state.isSideBarOpen}>
            <IconButton
              iconName={this.state.isSideBarOpen ? 'xmark-solid' : 'bars-solid'}
              onClick={this.toggleSidebar}
            />
          </BurgerIconWrapper>
          <UserIconWrapper ref={this.userButton} isSideBarOpen={this.state.isSideBarOpen}>
            <IconButton iconName="user-solid" onClick={this.toggleUserMenu} />
          </UserIconWrapper>
          <BackgroundIconWrapper ref={this.backgroundButton} isSideBarOpen={this.state.isSideBarOpen}>
            <IconButton
              iconName="image-solid"
              onClick={this.toggleBackgroundMenu}
            />
          </BackgroundIconWrapper>
        </PotreeWrapper>
      </ViewerWrapper>
    );
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.closeOpenMenus);
    const viewerElem = this.potreeContainerDiv.current;

    if (window.matchMedia('(max-width: 580px)').matches){
      this.setState({isSideBarOpen: false})
    };

    let viewer = new this.Potree.Viewer(viewerElem);

    viewer.setEDLEnabled(true);
    viewer.setFOV(60);
    viewer.setPointBudget(1 * 1000 * 1000);
    viewer.setClipTask(this.Potree.ClipTask.SHOW_INSIDE);
    viewer.loadSettingsFromURL();
    viewer.setBackground('none');
    viewer.setControls(viewer.orbitControls);

    viewer.loadGUI(() => {
      viewer.setLanguage('en');
      $('#menu_tools').next().show();
      $('#menu_clipping').next().show();
    });

    this.setState({ viewer: viewer });
    let initialUrl = this.state.initialProjectChoice
      ? this.state.options[this.state.initialProjectChoice].value
      : this.state.options[0].value;
    let url = initialUrl;
    this.loadNewPointCloud(url, viewer);
  }
}

const ViewerWrapper = styled.div<{isSideBarOpen: boolean}>`
  height: 100%;
  overflow: hidden;
  #potree_sidebar_container {
    width: 300px;
    @media screen and (max-width: ${ScreenSizes.viewerSmall}){
        width: ${(props)=> props.isSideBarOpen ? '100vw': '0px'};}

    #sidebar_root{
      width: 300px;
    @media screen and (max-width: ${ScreenSizes.viewerSmall}){
        width: ${(props)=> props.isSideBarOpen ? '100vw': '0px'};}
      .logo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

        .sidebar_logo{
          max-width: 300px;
        }

        .menu_wrapper{
          margin-right: 16px;
        }
        #potree_menu{
          @media screen and (max-width: ${ScreenSizes.viewerSmall}){
          margin-right: 16px;
          }
        }
      }
    }

  #potree_render_area {
    left: ${(props)=> props.isSideBarOpen ? '300px': '0px'};
    background: transparent
      radial-gradient(
        closest-side at 50% 50%,
        var(--theme-blue-005) 0%,
        var(--theme-blue-004) 100%
      )
      0% 0% no-repeat padding-box;
      @media screen and (max-width: ${ScreenSizes.viewerSmall}){
        left: ${(props)=> props.isSideBarOpen ? '100vw': '0px'};

}

  }
`;

const PotreeWrapper = styled.div`
`
const SidebarWrapper = styled.div`
`
const UserMenuWrapper = styled.div`
  position: absolute;
  top: 76px;
  right: 18px;
  width: 200px;
  z-index: 2;
  @media screen and (max-width: ${ScreenSizes.laptop}){
    top: 140px;
    right: 12px;
    width: 100px;
  }
  @media screen and (max-width: ${ScreenSizes.viewerSmall}){
  top: 140px;
  right: 12px;
}
`;

const BackgroundMenuWrapper = styled.div`
  position: absolute;
  bottom: 70px;
  right: 18px;
  width: 200px;
  z-index: 2;
  @media screen and (max-width: ${ScreenSizes.viewerSmall}){
  right: 12px;
}

`;

const SelectWrapper = styled.div<{isSideBarOpen: boolean}>`
  position: absolute;
  top: 16px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
  @media screen and (max-width: ${ScreenSizes.laptop}){
    z-index: 25;
  }
  @media screen and (max-width: ${ScreenSizes.viewerSmall}){
  z-index: 3;
  display: ${(props)=> props.isSideBarOpen ? 'none': 'block'};
  width: 100vw;
}
`;

const SelectContainer = styled.div`
  width: 100%;
  max-width: 420px;
  padding: 0 12px;
  height: 58px;
  box-shadow: 0px 2px 4px var(--theme-shadow);
  font-size: 1.5rem;
  font-family: var(--interface-font);
  .projectSelectContainer {

    .projectSelect__menu {
      margin-top: 0px;
    }
    .projectSelect__menu .projectSelect__menu-list {
      margin-top: 24px;
    }
    .projectSelect__menu .projectSelect__menu-list .projectSelect__option {
      padding-top: 14px;
      padding-bottom: 14px;
    }
    .projectSelect__control {
      padding: 12px 0px;
      .projectSelect__value-container {
        .projectSelect__single-value {
          .label {
            display: flex;
            justify-content: space-between;
          }
          .label-span {
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .date-span {
            font-weight: 400;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${ScreenSizes.laptop}){
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  z-index: 25;
}
`;

const IconWrapper = styled.div`
  position: absolute;
  width: 58px;
  height: 58px;
  z-index: 2;
  box-shadow: 0px 2px 4px var(--theme-shadow);
  button {
    padding: 0;
    width: 58px;
    height: 58px;
    position: relative;
    svg {
      fill: var(--theme-blue-004);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 58px;
      height: 58px;
    }
  }
`;

const UserIconWrapper = styled(IconWrapper)<{isSideBarOpen: boolean}>`
  top: 18px;
  right: 18px;
  opacity: 70%;
  &:hover {
    opacity: 100%;
  }
  button {
    opacity: 70%;
    &:hover {
      opacity: 100%;
    }
    svg {
      padding: 16px;
      opacity: 70%;
      &:hover {
        opacity: 100%;
      }
    }
  }
  @media screen and (max-width: ${ScreenSizes.laptop}){
  top: 88px;
  right: 12px;
}
  @media screen and (max-width: ${ScreenSizes.viewerSmall}){
  top: 88px;
  right: 12px;
  display: ${(props)=> props.isSideBarOpen ? 'none': 'block'};
}

`;

const BackgroundIconWrapper = styled(IconWrapper)<{isSideBarOpen: boolean}>`
  bottom: 18px;
  right: 18px;
  opacity: 70%;
  &:hover {
    opacity: 100%;
  }
  button {
    opacity: 70%;
    &:hover {
      opacity: 100%;
    }
    svg {
      padding: 16px;
      opacity: 70%;
      &:hover {
        opacity: 100%;
      }
    }
  }
  @media screen and (max-width: ${ScreenSizes.viewerSmall}){
  right: 12px;
  display: ${(props)=> props.isSideBarOpen ? 'none': 'block'};
}
`;

const BurgerIconWrapper = styled(IconWrapper)<{isSideBarOpen: boolean}>`
  top: 18px;
  left: 18px;
  opacity: 70%;
  &:hover {
    opacity: 100%;
  }
  button {
    opacity: 70%;
    &:hover {
      opacity: 100%;
    }
    svg {
      padding: 16px;
      opacity: 70%;
      &:hover {
        opacity: 100%;
      }
    }
  }
  @media screen and (max-width: ${ScreenSizes.laptop}){
  top: 88px;
  left: 12px;
}
  @media screen and (max-width: ${ScreenSizes.viewerSmall}){
  top: 88px;
  left: 12px;
  display: ${(props)=> props.isSideBarOpen ? 'none': 'block'};
}
`;


const SidebarBurgerIconWrapper = styled(IconWrapper)<{isSideBarOpen: boolean}>`
  position: fixed;
  display: none;
  top: 18px;
  right: 18px;
  z-index: 100;
  box-shadow: none;
  &:hover {
    opacity: 100%;
  }
  button {

    opacity: 70%;
    &:hover {
      opacity: 100%;
    }
    svg {
      padding: 12px;
      opacity: 70%;
      &:hover {
        opacity: 100%;
      }
    }
  }
  @media screen and (max-width: ${ScreenSizes.viewerSmall}){
  display: ${(props)=> props.isSideBarOpen ? 'block': 'none'};
}
`;

const propSelectStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    color: state.isSelected ? provided.color : 'var(--theme-blue-004)',
    backgroundColor: state.isSelected
      ? 'var(--theme-blue-004)'
      : provided.backgroundColor,
    padding: '14px',
    '&:hover': {
      color: 'var(--theme-secondary)',
      backgroundColor: 'var(--theme-blue-003)',
    },
    '.label': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '.label .label-span': {
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.label .date-span': {
      marginRight: '32px',
      fontWeight: '300',
    },
  }),
};
