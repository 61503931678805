const ScreenSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '428px',
  viewerSmall: '580px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export default ScreenSizes;
