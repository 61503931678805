export const demoItems = [
  {
    label: 'Takanawa Lion',
    thumbnail: 'assets/images/lion.png'
  },
  {
    label: '44 Station',
    thumbnail: 'assets/images/44Station.png'
  },

]
