import { NavLink } from 'react-router-dom';
import { Button } from '../Buttons/Buttons';
import styled from 'styled-components';
import ScreenSizes from '../../screensizes'


type HeaderLinkProps = {
  page: string;
};

type HeaderNavProps = {
  authClickHandler: () => void;
  signupClickHandler: () => void;
  isAuthenticated: boolean;
  headerStyle: string;
};

const HeaderNav = ({
  headerStyle,
  isAuthenticated,
  authClickHandler,
  signupClickHandler,
}: HeaderNavProps) => {

  const HeaderLink = ({ page }: HeaderLinkProps) => {
    const title = page.toUpperCase();
    return (
      <NavLink to={`/${page}`} className="navLink">
        {title}
      </NavLink>
    );
  };

  return (
    <StyledHeaderNav headerStyle={headerStyle}>
      <HeaderLinkWrapper>
        <HeaderLink page="property" />
        <HeaderLink page="visuals" />
        <HeaderLink page="pricing" />
        <HeaderLink page="support" />
      </HeaderLinkWrapper>
      <SigninWrapper>
        <Button buttonStyle="signin" onClick={authClickHandler}>
          {isAuthenticated ? 'SIGN OUT' : 'SIGN IN'}
        </Button>
        {!isAuthenticated ? (
          <Button buttonStyle="signup" onClick={signupClickHandler}>
            SIGN UP
          </Button>
        ) : null}
      </SigninWrapper>
    </StyledHeaderNav>
  );
};

export default HeaderNav;

const StyledHeaderNav = styled.div<any>`
    height: 4.8rem;
    font-family: var(--button-font);
    font-size: 1.6rem;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    color: ${(props) =>
      props.headerStyle === 'landing'
        ? 'var(--theme-secondary)'
        : 'var(--theme-primary)'};
    .active {
      border: 2px var(--theme-blue-001) solid;
      background-color: var(--theme-blue-001);
      color: var(--theme-secondary);
      &:hover {
        border: 2px var(--theme-blue-001) solid;
      }
    }
    .navLink {
      display: flex;
      align-items: center;
      margin-right: 10px;
      &:hover:not(.active) {
        background-color: var(--theme-blue-006);
      }
      &:active {
        background-color: var(--theme-blue-003);
      }
      border: 2px var(--theme-transparent) solid;
      border-radius: 4px;
      padding: 15px;
    }
    .navLink:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: ${ScreenSizes.laptop}){
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      .navLink {
        margin: 0;
        padding: 2px 6px;
      }
      button {
        margin-top: 10px;
        margin-bottom: 24px;
        padding: 4px 6px;
      }
    }
    @media screen and (max-width: ${ScreenSizes.mobileL}){
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      .navLink {
        margin-right: 0;
        margin: 0;
        padding: 2px 6px;
      }
    }
    `;

const HeaderLinkWrapper=styled.div`
display: flex;
@media screen and (max-width: ${ScreenSizes.mobileL}){
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      .navLink {
        margin-right: 0;
        padding: .6rem;
      }
    }
`
const SigninWrapper= styled.div`
display: flex;
 flex-wrap: nowrap;
 margin-left: 10px;
 @media screen and (max-width: ${ScreenSizes.mobileL}){
      margin: 12px auto;
      button{

        margin-top: 1.2rem;
        padding: .6rem;
      }
    }

`
